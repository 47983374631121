.ShareCatalogueField {
    margin-top: 1rem;

}

.order-page-large-cancel-button {
    display: flex;
    background-color: #ffffff;
    padding-inline: 1rem;
    padding-block: 0.5rem;
    border-radius: 32px;
    color: #000000;
    border: 0.4px solid #707070;
    margin-right: 1rem;
    cursor: pointer;
}



/* CSS */
.download-button {
    background-color: #1d4e4b;
    border-radius: 8px;
    border-style: none;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    margin-block: 15px;
    outline: none;
    padding: 5px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: color 100ms;
    vertical-align: baseline;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.download-button:hover {
    background-color: #1d4e4bde;
}